import React from "react";
import gallery1 from "../../Assets/gallery/gallery1.jpg";
import gallery2 from "../../Assets/gallery/gallery2.jpg";
const images = [
  gallery1,
  gallery2,
  "https://images.unsplash.com/photo-1470225620780-dba8ba36b745?ixlib=rb-4.0.3",
  "https://images.unsplash.com/photo-1429962714451-bb934ecdc4ec?ixlib=rb-4.0.3",
  "https://images.unsplash.com/photo-1493225457124-a3eb161ffa5f?ixlib=rb-4.0.3",
  "https://images.unsplash.com/photo-1514525253161-7a46d19cd819?ixlib=rb-4.0.3",
];

export default function Gallery() {
  return (
    <section id="gallery" className="bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4 animate-fade-in">GALLERY</h2>
          <div className="w-24 h-1 bg-[#B85EE6] mx-auto animate-fade-in"></div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {images.map((image, index) => (
            <div
              key={index}
              className="relative group overflow-hidden rounded-lg"
              onContextMenu={(e) => e.preventDefault()}
            >
              <img
                src={image}
                alt={`Gallery image ${index + 1}`}
                className="w-full h-96 object-cover transform group-hover:scale-110 transition duration-500"
              />
              <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                <span className="text-white text-lg font-semibold">
                  View Image
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
