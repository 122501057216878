import React from "react";
import { Facebook, Twitter, Instagram, Youtube } from "lucide-react";
import koratopLogo from "../../Assets/koratoplogo.png";
import { GrInstagram } from "react-icons/gr";
import { FaWhatsapp } from "react-icons/fa";
export default function Footer() {
  return (
    <footer className="bg-black text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid md:grid-cols-4 gap-8">
          <div>
            <img
              onContextMenu={(e) => e.preventDefault()}
              src={koratopLogo}
              alt="Koratop Logo"
              className="h-12 mb-4"
            />
            <p className="text-gray-400">
              Koratop 2.0 is a vibrant celebration of music, culture, and
              community in Koraput with electrifying performances and local
              experiences.
            </p>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <a href="#home" className="text-gray-400 hover:text-[#B85EE6]">
                  Home
                </a>
              </li>
              <li>
                <a href="#about" className="text-gray-400 hover:text-[#B85EE6]">
                  About
                </a>
              </li>
              <li>
                <a
                  href="#events"
                  className="text-gray-400 hover:text-[#B85EE6]"
                >
                  Events
                </a>
              </li>
              <li>
                <a
                  href="#gallery"
                  className="text-gray-400 hover:text-[#B85EE6]"
                >
                  Gallery
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Contact</h3>
            <ul className="space-y-2 text-gray-400">
              <li>616, Esplanade one</li>
              <li>Rasulgarh</li>
              <li>Bhubaneswar, India</li>
              <li>Phone: +91 94398 71345</li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              <a
                className="text-gray-400 hover:text-[#B85EE6]"
                href="https://www.instagram.com/koratopput/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GrInstagram className="w-6 h-6" />
              </a>

              <a
                className="text-gray-400 hover:text-[#B85EE6]"
                href="https://chat.whatsapp.com/FbmEA4f1GDN6WVXqCSCFHK"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp className="w-6 h-6" />
              </a>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-800 mt-12 pt-8 text-center text-gray-400">
          <p>
            &copy; {new Date().getFullYear()} Koratop Festival. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
