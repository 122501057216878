import React from "react";
import aanya from "../../Assets/artists/AANYA.jpg";
import artistry from "../../Assets/artists/artistry.jpg";
import aviam from "../../Assets/artists/aviam.jpg";
import biru from "../../Assets/artists/BIRU.jpg";
import bobAgain2 from "../../Assets/artists/BOB AGAIN 2.jpg";
import max from "../../Assets/artists/MAX.jpg";
import missterical from "../../Assets/artists/MISSTERICAL.jpg";
import neonNocva from "../../Assets/artists/neon nocva.jpg";
import pali from "../../Assets/artists/pali.jpg";
import rishiraj from "../../Assets/artists/rishiraj.jpg";
import sivaPrayojan from "../../Assets/artists/SIVA PRAOYJAN.jpg";
import vasuki from "../../Assets/artists/VASUKI.jpg";
import aditya from "../../Assets/artists/aditya.jpg";
import anselmroy from "../../Assets/artists/anselmroy.jpg";

const artists = [
  {
    id: 1,
    name: "Siva Prayojan",
    image: sivaPrayojan,
    bio: "Techno electronic artist",
  },
  {
    id: 2,
    name: "Missterical",
    image: missterical,
    bio: "Hypnotic and Psytechno",
  },
  { id: 3, name: "Neon Nova", image: neonNocva, bio: "Afrohouse and Techno" },
  { id: 4, name: "Bob Again", image: bobAgain2, bio: "Techno & Tech House" },
  { id: 5, name: "Pali", image: pali, bio: "" }, // No bio provided
  { id: 6, name: "Max", image: max, bio: "From home ground Play - Techno" },
  { id: 7, name: "Vasuki", image: vasuki, bio: "" }, // No bio provided
  { id: 8, name: "Aanya", image: aanya, bio: "Melodic and peak time Techno" },
  {
    id: 9,
    name: "Anselm Roy",
    image: anselmroy,
    bio: "Artist from Vizag for Techno lover",
  },
  { id: 10, name: "Biru", image: biru, bio: "" }, // No bio provided
  { id: 11, name: "Rishiraj", image: rishiraj, bio: "Artist from Bangalore" },
  { id: 12, name: "Aviam", image: aviam, bio: "Afrohouse and Techno" },
  { id: 13, name: "Aditya", image: aditya, bio: "Techno and EDM" },
  {
    id: 14,
    name: "Artistry",
    image: artistry,
    bio: "The newborn in Techno and EDM industry",
  },
];

export default function Artists() {
  return (
    <section
      id="artists"
      className="py-20 bg-gradient-to-b from-black to-gray-900"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Header */}
        <div className="text-center mb-16">
          <h2 className="text-4xl animate-fade-in font-bold text-white mb-4 tracking-wide uppercase">
            Artists
          </h2>
          <div className="w-28 h-1 bg-[#B85EE6] mx-auto mb-2 animate-fade-in"></div>
        </div>

        {/* Image Gallery */}
        <div
          onContextMenu={(e) => e.preventDefault()}
          className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6"
        >
          {artists.map((artist) => (
            <div
              key={artist.id}
              className="relative group overflow-hidden rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500"
            >
              <img
                src={artist.image}
                alt={artist.name}
                className="w-full h-full object-cover"
              />
              {/* Overlay */}
              <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
              {/* Caption */}
              <div className="absolute bottom-4 left-4 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                <p className="text-sm">{artist.bio}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
