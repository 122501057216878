import React, { useEffect, useState } from "react";
import { FiMenu, FiSearch, FiX } from "react-icons/fi";
import koratoplogo from "../../Assets/koratoplogo.png";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaWhatsapp,
} from "react-icons/fa";

const Header = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const handleToggleMenu = () => {
    setIsMenuVisible((prevIsMenuVisible) => !prevIsMenuVisible);
  };

  // Disable scrolling on body when menu is open
  useEffect(() => {
    if (isMenuVisible) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isMenuVisible]);

  const navLinks = [
    { label: "Home", href: "#home" },
    { label: "Event", href: "#events" },
    { label: "Gallery", href: "#gallery" },
    { label: "About Us", href: "#about" },
    { label: "Artists", href: "#artists" },
    { label: "Facility", href: "#facility" },
    { label: "Contact", href: "#contact" },
  ];

  const socialLinks = [
    { icon: <FaWhatsapp />, href:"https://chat.whatsapp.com/FbmEA4f1GDN6WVXqCSCFHK"},
    { icon: <FaInstagram />, href:"https://www.instagram.com/koratopput/" },
  ];

  return (
    <>
      <header className="bg-gradient-to-r from-black via-gray-900 to-black py-4 sticky top-0 w-full z-50 shadow-md">
        <div className="flex items-center justify-between px-6">
          {/* Logo */}
          <div className="flex items-center">
            <img
              onContextMenu={(e) => e.preventDefault()}
              src={koratoplogo}
              alt="Koratop Logo"
              className="h-12 transition-transform duration-500 ease-in-out hover:scale-110 hover:rotate-6"
            />
          </div>

          {/* Hamburger Menu and Search Icon */}
          <div className="flex items-center space-x-6 text-white text-xl">
            {isMenuVisible ? (
              <FiX
                className="cursor-pointer text-2xl transition-colors duration-300 ease-in-out hover:text-[#B85EE6]"
                onClick={handleToggleMenu}
              />
            ) : (
              <FiMenu
                className="cursor-pointer text-2xl transition-colors duration-300 ease-in-out hover:text-[#B85EE6]"
                onClick={handleToggleMenu}
              />
            )}
            <FiSearch className="cursor-pointer text-2xl hover:text-[#B85EE6] transition-all" />
          </div>
        </div>
      </header>

      {/* Hamburger Menu Section */}
      <nav
        className={`fixed bg-gradient-to-b from-black via-gray-800 to-black text-white top-0 left-0 w-full h-full z-40 px-8 transform transition-transform duration-500 ease-in-out ${
          isMenuVisible
            ? "translate-y-0 opacity-100"
            : "-translate-y-full opacity-0"
        }`}
      >
        <div className="flex flex-col items-center gap-5 mt-32 transition-opacity duration-300">
          {/* Navigation Links */}
          {navLinks.map((link, index) => (
            <a
              key={index}
              href={link.href}
              className="text-xl font-semibold opacity-75 hover:opacity-100 hover:text-[#B85EE6] transition-all duration-300 ease-in-out"
              onClick={() => setIsMenuVisible(false)} // Close the menu after clicking
            >
              {link.label}
            </a>
          ))}
          {/* Divider */}
          <hr className="w-3/4 opacity-40" />
          {/* Social Icons */}
          <div className="flex gap-6">
            {socialLinks.map((link, index) => (
              <a
                key={index}
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
                className="text-2xl hover:text-[#B85EE6] transition-colors duration-300 ease-in-out"
              >
                {link.icon}
              </a>
            ))}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
