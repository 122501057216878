import About from "./components/sections/About";
import Artists from "./components/sections/Artists";
import Contact from "./components/sections/Contact";
import EditionLineup from "./components/sections/EditionLineup";
import Events from "./components/sections/Events";
import Footer from "./components/sections/Footer";
import Gallery from "./components/sections/Gallery";
import Header from "./components/sections/Header";
import { Hero } from "./components/sections/Hero";
import HotelSection from "./components/sections/HotelSection";
import Services from "./components/sections/Services";
import TicketsLive from "./components/sections/TicketsLive";
import './App.css';
function App() {
  return (
    <main className="min-h-screen bg-black">
      <Header />
      <Hero />
      <TicketsLive />
      <About />
      <Events />
      <EditionLineup/>
      <Artists />
      <HotelSection/>
      <Services/>
      <Gallery />
      <Contact />
      <Footer />
    </main>
  );
}

export default App;
