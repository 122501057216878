import React from "react";
import { Heading } from "../ui/Typography";

const About = () => {
  return (
    <section id="about" className="bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <Heading className="mb-4 md:mb-6 animate-fade-in">ABOUT KORATOP</Heading>

          <div className="w-24 h-1 bg-[#B85EE6] mx-auto"></div>
        </div>

        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div>
            <img
              onContextMenu={(e) => e.preventDefault()}
              src="https://images.unsplash.com/photo-1516450360452-9312f5e86fc7?ixlib=rb-4.0.3"
              alt="Festival crowd"
              className="rounded-lg shadow-xl"
            />
          </div>
          <div className="text-white animate-fade-in">
            <p className="text-base">
              Welcome to Koratop 2.0, the ultimate celebration of music and
              culture in the heart of Koraput. Set against the serene backdrop
              of its picturesque lakes, this festival is a vibrant blend of
              electrifying music, engaging experiences, and unforgettable
              memories. At Koratop 2.0, we bring together diverse
              audiences—students, ravers, bike enthusiasts, and music lovers
              from across the region—to enjoy an immersive lineup featuring
              techno, EDM, live instrumentals, and more. Beyond music, indulge
              in local cuisine, creative workshops, and unique activities that
              make this event truly special.
            </p>

            <button className="bg-[#B85EE6] mt-3 mb-6 text-white px-6 py-3 rounded-full font-semibold hover:bg-[#B85EE6]/90 transition">
              Learn More
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
