import React from 'react';

export function Button({ children, variant = 'primary', className = '', onClick }) {
  const baseStyles = 'px-6 sm:px-8 py-3 rounded-lg font-medium transition-all duration-200 text-base sm:text-lg whitespace-nowrap';
  const variants = {
    primary: 'bg-purple-500 text-white hover:bg-purple-600 active:bg-purple-700 shadow-lg hover:shadow-xl',
    secondary: 'bg-white/10 text-white border border-white/20 hover:bg-white/20 active:bg-white/30 shadow-md hover:shadow-lg'
  };

  return (
    <button 
      className={`${baseStyles} ${variants[variant]} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}