import React from "react";
import lineupImg from "../../Assets/lineupImg.jpeg";
const EditionLineup = () => {
  return (
    <section id="lineup" className="">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-10">
        {/* Section Header */}
        <div className="text-center mb-16">
          <h2 className="text-4xl animate-fade-in font-bold text-white mb-4 tracking-wide uppercase">
            Edition 2.0 lineup
          </h2>
          <div className="w-28 h-1 bg-[#B85EE6] mx-auto mb-2 animate-fade-in"></div>
        </div>

        <div>
          <img
            onContextMenu={(e) => e.preventDefault()}
            src={lineupImg}
            className="w-full"
            alt="lineupImg"
          />
        </div>
      </div>
    </section>
  );
};

export default EditionLineup;
