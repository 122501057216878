import React from 'react';
import { Mail, Phone, MapPin } from 'lucide-react';

export default function Contact() {
  return (
    <section id="contact" className="py-20 bg-zinc-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4 animate-fade-in">CONTACT US</h2>
          <div className="w-24 h-1 bg-[#B85EE6] mx-auto animate-fade-in"></div>
        </div>

        <div className="grid md:grid-cols-2 gap-12">
          <div>
            <form className="space-y-6">
              <div>
                <input
                  type="text"
                  placeholder="Your Name"
                  className="w-full px-4 py-3 bg-zinc-800 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-[#B85EE6]"
                />
              </div>
              <div>
                <input
                  type="email"
                  placeholder="Your Email"
                  className="w-full px-4 py-3 bg-zinc-800 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-[#B85EE6]"
                />
              </div>
              <div>
                <textarea
                  rows={4}
                  placeholder="Your Message"
                  className="w-full px-4 py-3 bg-zinc-800 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-[#B85EE6]"
                ></textarea>
              </div>
              <button className="w-full bg-[#B85EE6] text-white px-6 py-3 rounded-full font-semibold hover:bg-[#B85EE6]/90 transition">
                Send Message
              </button>
            </form>
          </div>

          <div className="text-white space-y-8">
            <div className="flex items-start space-x-4">
              <MapPin className="w-6 h-6 text-[#B85EE6] flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-2">Address</h3>
                <p className="text-gray-300">616, Esplanade one, Rasulgarh, Bhubaneswar</p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <Phone className="w-6 h-6 text-[#B85EE6] flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-2">Phone</h3>
                <p className="text-gray-300">+91 94398 71345</p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <Mail className="w-6 h-6 text-[#B85EE6] flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-2">Email</h3>
                <p className="text-gray-300">Koratopput@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}