import React from "react";

export default function RunningText() {
  return (
    <div className="relative w-full overflow-hidden bg-black py-2 mt-7">
      <div className="absolute inset-0 flex">
        {/* First set of running text */}
        <div className="animate-marquee whitespace-nowrap flex items-center">
          <span className="text-white mx-4">MISSTERCIAL</span>
          <span className="text-[#B85EE6] mx-4">SIVA PRAYOJAN</span>
          <span className="text-white mx-4">NEON NOVA</span>
          <span className="text-[#B85EE6] mx-4">BOB AGAIN</span>
          <span className="text-white mx-4">PALI</span>
          <span className="text-white mx-4">MAX</span>
          <span className="text-[#B85EE6] mx-4">VASUKI</span>
          <span className="text-white mx-4">AANYA</span>
          <span className="text-[#B85EE6] mx-4">BIRU</span>
          <span className="text-white mx-4">RISHIRAJ</span>
          <span className="text-[#B85EE6] mx-4">AVIAM</span>
          <span className="text-white mx-4">ANSLEM</span>
          <span className="text-[#B85EE6] mx-4">ADITYA</span>
          <span className="text-white mx-4">ARTISTRY</span>
        </div>

        {/* Duplicate for seamless loop */}
        <div className="animate-marquee2 whitespace-nowrap flex items-center">
          <span className="text-white mx-4">MISSTERCIAL</span>
          <span className="text-[#B85EE6] mx-4">SIVA PRAYOJAN</span>
          <span className="text-white mx-4">NEON NOVA</span>
          <span className="text-[#B85EE6] mx-4">BOB AGAIN</span>
          <span className="text-white mx-4">PALI</span>
          <span className="text-white mx-4">MAX</span>
          <span className="text-[#B85EE6] mx-4">VASUKI</span>
          <span className="text-white mx-4">AANYA</span>
          <span className="text-[#B85EE6] mx-4">BIRU</span>
          <span className="text-white mx-4">RISHIRAJ</span>
          <span className="text-[#B85EE6] mx-4">AVIAM</span>
          <span className="text-white mx-4">ANSLEM</span>
          <span className="text-[#B85EE6] mx-4">ADITYA</span>
          <span className="text-white mx-4">ARTISTRY</span>
        </div>
      </div>
    </div>
  );
}
