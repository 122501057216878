import React from "react";
import HotelCard from "./HotelCard";
import facility1 from "../../Assets/facility/facility1.jpg";
import facility2 from "../../Assets/facility/facility2.jpg";
import facility3 from "../../Assets/facility/facility3.jpg";
import facility4 from "../../Assets/facility/facility4.jpg";

const hotels = [
  {
    id: 1,
    image: facility1,
  },
  {
    id: 2,
    image: facility2,
  },
  {
    id: 3,
    image: facility3,
  },
  {
    id: 4,
    image: facility4,
  },
];

const HotelSection = () => {
  return (
    <section
      id="facility"
      className="py-20 bg-[#121212] relative overflow-hidden"
    >
      <div className="absolute inset-0 bg-gradient-to-b from-purple-900/10 to-transparent opacity-50" />
      <div className="container mx-auto px-4 relative">
        <div className="text-center mb-16">
          <h2 className="text-4xl animate-fade-in font-bold text-white mb-4 tracking-wide uppercase">
            Book Your Stay
          </h2>
          <div className="w-28 h-1 bg-[#B85EE6] mx-auto mb-2 animate-fade-in"></div>
        </div>

        <p className="text-gray-400 text-base mx-auto mb-8 max-w-4xl">
          Experience luxury accommodations within walking distance of the venue.
          Each hotel has been carefully selected to ensure your comfort and
          convenience.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {hotels.map((hotel, index) => (
            <div key={hotel.id} style={{ animationDelay: `${index * 200}ms` }}>
              <HotelCard {...hotel} />
            </div>
          ))}
        </div>
        <div
          className="mt-16 text-center animate-fade-in"
          style={{ animationDelay: "600ms" }}
        ></div>
      </div>
    </section>
  );
};

export default HotelSection;
