import React from "react";
import RunningText from "./RunningText";

const TicketsLive = () => {
  const tickets = [
    {
      title: "Koratop 2024",
      artist: "Lankaput",
      status: "TICKETS LIVE NOW",
    },
  ];
  return (
    <>
      <RunningText />
      <section className="relative py-16 bg-black overflow-hidden">
        {/* Diagonal Stripes Background */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 transform -rotate-12">
            <div className="flex space-x-8">
              {[...Array(3)].map((_, i) => (
                <div
                  key={i}
                  className="flex-none w-full transform translate-y-1/3"
                >
                  <div className="h-16 bg-white/10 -rotate-12"></div>
                  <div className="h-16 bg-[#B85EE6]/20 mt-8 -rotate-12"></div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="relative max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold text-white mb-4 animate-fade-in">
              TICKETS LIVE NOW
            </h2>
            <div className="w-24 h-1 bg-[#B85EE6] mx-auto animate-fade-in"></div>
          </div>

          <div className="grid md:grid-cols-1 gap-8">
            {tickets.map((ticket, index) => (
              <div
                key={index}
                className="bg-zinc-900/80 backdrop-blur-sm rounded-lg p-8 border border-zinc-800 hover:border-[#B85EE6] transition-colors"
              >
                <div className="text-center">
                  <h3 className="text-2xl font-bold text-white mb-2">
                    {ticket.title}
                  </h3>
                  {ticket.subtitle && (
                    <p className="text-lg text-gray-300 mb-4">
                      {ticket.subtitle}
                    </p>
                  )}
                  {ticket.artist && (
                    <p className="text-xl text-[#B85EE6] mb-4">
                      {ticket.artist}
                    </p>
                  )}
                  <div className="inline-block bg-[#B85EE6] text-white px-6 py-2 rounded-full font-semibold">
                    {ticket.status}
                  </div>
                  <a
                    href="https://www.skillboxes.com/events/koratop-music-festival-2-1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="w-full mt-6 bg-white text-black px-6 py-3 rounded-full font-semibold hover:bg-[#B85EE6] hover:text-white transition">
                      Book Now
                    </button>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default TicketsLive;
