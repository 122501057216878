import React, { useState, useEffect } from "react";

const EventCountdown = () => {
  // Target date for the event
  const targetDate = new Date("December 20, 2024 00:00:00").getTime();

  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // Update the timer every second
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (distance <= 0) {
        clearInterval(interval);
      } else {
        setTimeRemaining({
          days: Math.floor(distance / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((distance % (1000 * 60)) / 1000),
        });
      }
    }, 1000);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [targetDate]);

  return (
    <section
      className="bg-cover bg-center text-white sm:px-12 border-t border-gray-800 p-6 shadow-inner"
      style={{
        backgroundImage:
          "url('https://designtemplate.tech/images/Mystic%20Dark%20Smoke%20Overlay%20Background%20for%20Horror%20and%20Thriller%20Films-HD.webp')", // Replace with your desired background image
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="max-w-4xl mx-auto text-center">
        <p className="text-xl sm:text-2xl mb-8 opacity-90 animate-fade-in">
          Get Ready for the Ultimate Event Countdown!
        </p>

        {/* Countdown Blocks Section */}
        <div className="flex justify-center gap-6 sm:gap-8 items-center mb-12 px-24">
          {/* Days Block */}
          <div
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
            }}
            className="bg-opacity-70 backdrop-blur-lg rounded-lg p-4 sm:p-6  transition-all duration-300 text-center w-20 sm:w-32"
          >
            <div className="text-3xl sm:text-5xl font-bold text-gray-100">
              {timeRemaining.days}
            </div>
            <span className="text-xs sm:text-lg text-gray-300">Days</span>
          </div>

          {/* Hours Block */}
          <div
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
            }}
            className="bg-opacity-70 backdrop-blur-lg rounded-lg p-4 sm:p-6 transition-all duration-300 text-center w-20 sm:w-32"
          >
            <div className="text-3xl sm:text-5xl font-bold text-gray-100">
              {timeRemaining.hours}
            </div>
            <span className="text-xs sm:text-lg text-gray-300">Hours</span>
          </div>

          {/* Minutes Block */}
          <div
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
            }}
            className="bg-opacity-70 backdrop-blur-lg rounded-lg p-4 sm:p-6  transition-all duration-300 text-center w-20 sm:w-32"
          >
            <div className="text-3xl sm:text-5xl font-bold text-gray-100">
              {timeRemaining.minutes}
            </div>
            <span className="text-xs sm:text-lg text-gray-300">Minutes</span>
          </div>

          {/* Seconds Block */}
          <div
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
            }}
            className="bg-opacity-70 backdrop-blur-lg rounded-lg p-4 sm:p-6  transition-all duration-300 text-center w-20 sm:w-32"
          >
            <div className="text-3xl sm:text-5xl font-bold text-gray-100">
              {timeRemaining.seconds}
            </div>
            <span className="text-xs sm:text-lg text-gray-300">Seconds</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventCountdown;
