import React from "react";
import gallery1 from "../../Assets/gallery/gallery1.jpg";
import gallery2 from "../../Assets/gallery/gallery2.jpg";

import tent from "../../Assets/servics/tent.jpg";
import bus from "../../Assets/servics/bus.jpg";
import map from "../../Assets/servics/map.jpg";
import booktents from "../../Assets/servics/booktents.jpg";
const images = [tent, bus, map, booktents];

export default function Services() {
  return (
    <section id="services" className="py-20 bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl animate-fade-in font-bold text-white mb-4">
            OUR SERVICES
          </h2>
          <div className="w-24 h-1 bg-[#B85EE6] mx-auto animate-fade-in"></div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {images.map((image, index) => (
            <div
              key={index}
              className="relative group overflow-hidden rounded-lg"
            >
              <img
                src={image}
                alt={`Gallery image ${index + 1}`}
                className="w-full h-96 object-cover"
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
