import React from "react";

const HotelCard = ({ image, name }) => {
  return (
    <div
      onContextMenu={(e) => e.preventDefault()}
      className="animate-fade-in bg-[#1a1a1a] rounded-xl border overflow-hidden"
    >
      <div className="relative group">
        <img src={image} alt={name} className="w-full h-full object-cover" />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 transition-opacity duration-300" />
      </div>
    </div>
  );
};

export default HotelCard;
