import React from "react";
import { Ticket } from "lucide-react";
import { Container } from "../layout/Container";
import { Button } from "../ui/Button";
import { Heading } from "../ui/Typography";
import koratopImg from "../../Assets/koratopmusic.jpeg";
import EventCountdown from "./EventCountDown";
import { FaWhatsapp } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";

export function Hero() {
  return (
    <>
      <section id="home">
        <div>
          <img
            onContextMenu={(e) => e.preventDefault()}
            src={koratopImg}
            alt="koratop"
            className="w-full"
          />
        </div>
        <div className="relative min-h-[60vh] md:h-[70vh] flex items-center justify-center text-white overflow-hidden">
          <div
            className="absolute inset-0 bg-black"
            style={{
              backgroundImage:
                "url(https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              filter: "brightness(0.3)",
            }}
          />

          <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent" />

          <Container className="relative z-10">
            <div className="text-center">
              <Heading className="mb-4 md:mb-6 animate-fade-in">
                TICKETS LIVE NOW
              </Heading>
              <div className="w-24 h-1 bg-[#B85EE6] mx-auto animate-fade-in"></div>
              <div className="flex flex-col gap-3 justify-center items-stretch mt-8 md:mt-10">
                <Button
                  variant="primary"
                  className="w-full sm:w-auto mx-auto group"
                >
                  <a
                    href="https://www.skillboxes.com/events/koratop-music-festival-2-1"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center gap-2"
                  >
                    <Ticket className="w-5 h-5 transition-transform group-hover:scale-110" />
                    BOOK NOW
                  </a>
                </Button>

                <div className="grid sm:grid-cols-2 gap-3 w-full max-w-lg mx-auto">
                  <a
                    href="https://chat.whatsapp.com/FbmEA4f1GDN6WVXqCSCFHK"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button variant="secondary" className="w-full group">
                      <span className="flex items-center justify-center gap-2">
                        <FaWhatsapp className="w-5 h-5 transition-transform group-hover:rotate-12 text-green-500" />{" "}
                        Join Our Community
                      </span>
                    </Button>
                  </a>

                  <a
                    href="https://www.instagram.com/koratopput/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button variant="secondary" className="w-full group">
                      <span className="flex items-center justify-center gap-2">
                        <GrInstagram className="w-5 h-5 transition-transform group-hover:scale-110" />
                        Join Us On Instagram
                      </span>
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <EventCountdown />
      </section>

      {/* Fixed WhatsApp Icon */}
      <div className="fixed bottom-36 right-4 z-50">
        <a
          href="https://wa.me/9337922103"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600 transition-all duration-300"
        >
          <FaWhatsapp className="w-6 h-6" />
        </a>
      </div>
    </>
  );
}
