import React from "react";
import upcominImg from "../../Assets/upcoming.jpeg";
const events = [
  {
    title: "Koratop 2024",
    date: "20-22 December 2024",
    location: "Koraput",
    image: upcominImg,
  },
];

export default function Events() {
  return (
    <section id="events" className="py-20 bg-zinc-900">
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4 animate-fade-in">
            UPCOMING EVENTS
          </h2>
          <div className="w-24 h-1 bg-[#B85EE6] mx-auto animate-fade-in"></div>
        </div>

        <div className="grid grid-cols-1 gap-8">
          {events.map((event, index) => (
            <div
              key={index}
              className="group relative overflow-hidden rounded-lg"
            >
              <img
                src={event.image}
                alt={event.title}
                className="w-full h-[500px] object-cover transform group-hover:scale-110 transition duration-500"
                onContextMenu={(e) => e.preventDefault()}
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent flex flex-col justify-end p-6">
                <h3 className="text-2xl font-bold text-white mb-2">
                  {event.title}
                </h3>
                <p className="text-[#B85EE6] mb-1">{event.date}</p>
                <p className="text-gray-300">{event.location}</p>
                <a
                  href="https://www.skillboxes.com/events/koratop-music-festival-2-1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="mt-4 bg-[#B85EE6] text-white px-6 py-2 rounded-full font-semibold hover:bg-[#B85EE6]/90 transition">
                    Book Now
                  </button>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
